import { ContentContext } from '@components/layouts'
import React, { useContext } from 'react'

const OurContact = () => {
    const { translate } = useContext(ContentContext)
    return (
        <section className="wrapper bg-soft-primary mb-10">
            <div className="container py-14 py-md-16">
                <div className="row">
                    <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto text-center">
                        <h2 className="fs-15 text-uppercase text-muted mb-3">
                            {translate('right_position')}
                        </h2>
                        <h3 className="display-4 mb-7 px-lg-5 px-xl-0 px-xxl-5">
                            {translate('right_position_text')}
                        </h3>
                        <a href="mailto:hr@nanodev.ca" className="btn btn-lg btn-primary rounded-pill">
                            {translate('contact_us')}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurContact