import React, { useContext } from 'react'
import { ContentContext } from '@components/layouts'

const Banner = () => {
    const { translate } = useContext(ContentContext)
    return (
        <section className="wrapper position-relative"
        style={{
            backgroundImage: "url('/bg22.png')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
        }}
        >
            <div className="container pt-10 pb-17 pt-md-14 text-center">
                <div className="row">
                    <div className="col-lg-8 mx-auto mb-5">
                        <h1 className="fs-15 text-uppercase  mb-3">
                            {translate('career_title_text')}
                        </h1>
                        <h3 className="display-1 mb-6">
                            {translate('career_head_text')}
                        </h3>
                        <a
                            href="#job"
                            className="btn btn-lg btn-primary rounded-pill scroll"
                        >
                            {translate('explore_position')}
                        </a>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden">
                <div className="divider text-light mx-n2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 60">
                        <path
                            fill="currentColor"
                            d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z"
                        ></path>
                    </svg>
                </div>
            </div>
        </section>
    )
}

export default Banner