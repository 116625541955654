import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import { ContentContext } from '@components/layouts'
import { formatJobs } from '../../services/careers'

const DisplayJob = () => {
  const jobs = useStaticQuery(graphql`
    query Jobs {
      allStrapiJob {
        nodes {
          localizations {
            data {
              attributes {
                locale
                form
                category
                requirements
                description
                responsibilities
                title
                type
                validity
              }
            }
          }
          form
          id
          title
          type
          category
          validity
          description {
            data {
              description
            }
          }
          requirements {
            data {
              requirements
            }
          }
          responsibilities {
            data {
              responsibilities
            }
          }
        }
      }
    }
  `)


  const { lang, translate } = useContext(ContentContext)
  const [filterA, setFilterA] = useState()
  const [filterB, setFilterB] = useState()
  const [careers, setCareers] = useState<Array<object>>(
    formatJobs(jobs.allStrapiJob.nodes)
  )
  console.log({careers})
  const Categories = [...new Set(careers.map((val: any) => val?.category))]

  const filterItem = (event: any) => {
    let category = event.target.value
    setFilterA(category)
  }
  const Types = [...new Set(careers.map((val: any) => val?.type))]
  const filterType = (event: any) => {
    let type = event.target.value
    setFilterB(type)
  }

  useEffect(() => {
    setCareers(
      careers.filter(
        (item: any) =>
          (!filterA || item.category === filterA) &&
          (!filterB || item.type === filterB)
      )
    )
  }, [filterA, filterB])
  return (
    <>
      <div className="row text-center" id="job">
        <div className="col-xl-10 mx-auto">
          <h2 className="fs-15 text-uppercase text-muted mb-3">
            {translate('job_positions')}
          </h2>
          <h3 className="display-4 mb-10 px-xxl-15">{translate('job_text')}</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-10 mx-auto">
          <form className="filter-form mb-10">
            <div className="row">
              <div className="col-md-5 mb-3">
                <div className="form-select-wrapper">
                  <select
                    className="form-select"
                    defaultValue={'Position'}
                    aria-label=""
                    onChange={e => filterItem(e)}
                  >
                    <option disabled>Position</option>
                    {Categories.map((val: any, id) => {
                      return (
                        <option value={val} key={id}>
                          {' '}
                          {val}{' '}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-5 mb-3">
                <div className="form-select-wrapper">
                  <select
                    className="form-select"
                    defaultValue={'Type'}
                    aria-label=""
                    onChange={e => filterType(e)}
                  >
                    <option disabled>Type</option>
                    {Types.map((val: any, id) => {
                      return (
                        <option value={val} key={id}>
                          {val}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <button
                className="col-md-2 mb-3 btn btn-sm btn-primary rounded-pill"
                onClick={e => {
                  e.preventDefault()
                  setCareers(careers)
                }}
              >
                {translate('alljobs')}
              </button>
            </div>
          </form>
          <div className="job-list mb-10">
            <div className="d-flex justify-content-between align-items-baseline mb-5">
              <h3 className="mb-4">{translate('current_jobs')}</h3>
            </div>
            <div>
              {careers?.map((Val: any, id: any) => {
                // console.log(Val);
                
                return (
                  Val.validity && (
                    <Link
                      to={`/description/${Val?.id}`}
                      key={id}
                      className="card mb-4 lift"
                    >
                      <div className="card-body p-5">
                        <span className="row justify-content-between align-items-center">
                          <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                            <span className="avatar bg-primary text-white w-9 h-9 fs-17 me-3" 
                            style={{marginLeft: lang === 'ar' ? '10px' : ''}}
                            >
                              <i className="uil uil-bag-alt "></i>
                            </span>{' '}
                            {
                              lang === 'en'
                              ? Val.title
                              : lang === 'fr'
                                ? Val?.localizations.data.map((data: { attributes: { locale: string; title: any } }) =>{
                                  if (data.attributes.locale === 'fr') {
                                    return data.attributes?.title
                                  }
                                })
                                : lang === 'ar'
                                  ? Val?.localizations.data.map((data: { attributes: { locale: string; title: any } }) =>{
                                    if (data.attributes.locale === 'ar') {
                                      return data.attributes?.title
                                    }
                                  })
                                  : Val.title
                            }{' '}
                          </span>
                          <span className="col-5 col-md-3 text-body d-flex align-items-center"
                          >
                            <i className="uil uil-clock me-1" style={{marginLeft: lang === 'ar' ? '10px' : ''}}></i>{' '}
                            {
                              lang === 'en'
                              ? Val.type
                              : lang === 'fr'
                                ? Val?.localizations.data[0]?.attributes?.type
                                : lang === 'ar'
                                  ? Val?.localizations.data[1]?.attributes?.type
                                  : Val.type
                            }{' '}
                          </span>
                          <span className="d-none d-lg-block col-1 text-center text-body">
                            <i className={`uil ${lang === 'ar' ? 'uil-angle-left-b' : 'uil-angle-right-b'}`}></i>
                          </span>
                        </span>
                      </div>
                    </Link>
                  )
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DisplayJob
