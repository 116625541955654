import React, { useContext } from 'react'
import { ContentContext } from '@components/layouts'
interface ICareerCard {
    image: React.SVGProps<SVGSVGElement>,
    title: string,
    description: string
}

const CareerCard: React.FC<ICareerCard> = ({image, title, description}) => {
    const { translate } = useContext(ContentContext)
    return (
        <div className="col-md-6 col-xl-3">
            <div className="card lift">
                <div className="card-body">
                    <>
                        {image}
                        <h4>{translate(title)}</h4>
                        <p className="mb-0 text-justify">
                            {translate(description)}
                        </p>
                    </>
                </div>
            </div>
        </div>
    )
}

export default CareerCard