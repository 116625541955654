import React from 'react'
import Footer from '../../components/footer'
import {
  CareerContextProvider
} from '../../context/CareerContext'
import Nav from '@components/nav'
import Banner from './Banner'
import JobSection from './JobSection'
import OurContact from './OurContact'

const Career = () => {

  return (
    <CareerContextProvider>
      <>
        <Nav />

        <Banner />

        <JobSection />

        <OurContact />
        <Footer />
      </>
    </CareerContextProvider>
  )
}

export default Career
