const formatJobs = (jobs: any[]) => {
    let finalJobs: any = []
    jobs.map((job: any) => {
      let career = {
        id: job.id,
        title: job.title,
        type: job.type,
        validity: job.validity,
        form: job.form,
        category: job.category,
        localizations: {
          data: getFormatedData(job)
        },
        requirements: {
          data: {
            requirements: job.requirements
          }
        },
        responsibilities: {
          data: {
            responsibilities: job.responsibilities
          }
        },
        description: {
          data: {
            description: job.description
          }
        }
      }

      finalJobs.push(career)
    })
    return finalJobs
  }

  const getFormatedData = (job: any) => {
    return job.localizations?.data?.map((data: any) => {
      return {
        attributes: {
          id: job.id,
          category: data?.attributes?.category,
          form: data?.attributes?.form,
          locale: data?.attributes?.locale,
          requirements:
            data?.attributes?.requirements,
          responsibilities:
            data?.attributes?.responsibilities,
          title: data?.attributes?.title,
          type: data?.attributes?.type,
          validity: data?.attributes?.validity,
          description: data?.attributes?.description
        }
      }
    })
  }

  export default formatJobs